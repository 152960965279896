import Session from "@app/class/Session";
import { Link, matchPath, useLocation } from "react-router-dom";

import { Button, Dropdown, Option, Typography } from "@launchmetrics/lm-ui";

import "./navbar.scss";
import { sections } from "./sections";

const session = new Session();
function Navbar() {
	const location = useLocation();

	const isActive = (path: string) => {
		return !!matchPath({ end: false, path: path }, location.pathname);
	};

	const sectionsMenu = sections.map(
		(section: { name: string; url: string; childrens: { name: string; url: string }[] }) => (
			<Dropdown
				key={section.url}
				popoverTrigger={
					<Button
						id="dropdown-section-menu-button"
						onPointerEnterCapture={undefined}
						onPointerLeaveCapture={undefined}
						placeholder={section.name}
						children={section.name}
						className={`${isActive(section.url) ? " selected" : ""}`}
					/>
				}
			>
				<div className="dropdown-section-menu-option">
					{section.childrens.map((child: { name: string; url: string }) => (
						<Link to={child.url} key={child.url}>
							<Option value={child.url} key={child.url}>
								{child.name}
							</Option>
						</Link>
					))}
				</div>
			</Dropdown>
		)
	);

	const userMenu = (
		<Dropdown
			popoverTrigger={
				<Button
					onPointerEnterCapture={undefined}
					onPointerLeaveCapture={undefined}
					styleProps={{ width: "110px" }}
					variant="textGray"
					placeholder={session.getUserName()}
					children={session.getUserName()}
				/>
			}
		>
			<div className="dropdown-user-menu-option">
				<Link to="/bsso/logout">
					<Option value="logout">Log Out</Option>
				</Link>
			</div>
		</Dropdown>
	);

	return (
		<>
			<div className="header">
				<span className="logo">
					<Link to="/">
						<img src="/logo.svg" width={40} height={40} alt="Orange -  Tool" />
						<Typography as="h3" id="logo-title" children="Orange Tool" />
					</Link>
				</span>
				<div className="user-menu">{userMenu}</div>
			</div>
			<nav className="sections-menu">{sectionsMenu}</nav>
		</>
	);
}

export default Navbar;
