export const sections = [
	{
		name: "Media",
		url: "/media",
		childrens: [
			{
				name: "Media Search",
				url: "/media"
			},
			{
				name: "Insert Media",
				url: "/media/insert"
			}
		]
	},
	{
		name: "Documents",
		url: "/documents",
		childrens: [
			{
				name: "Document Search",
				url: "/documents/search"
			},
			{
				name: "Bulk Import URLs",
				url: "/documents/ingest"
			}
		]
	}
];
