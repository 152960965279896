import { EditMediaRefMethods } from "@app/components/main/media/edit/types";
import { useFujiGet } from "@app/hooks/useFuji";
import { MediaSections } from "@fuji/media";
import React from "react";
import { useQuery } from "react-query";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";

import { Button, Loader, Tab, Tabs } from "@launchmetrics/lm-ui";

import "./media.scss";

function MediaLayout() {
	const params = useParams();
	const navigate = useNavigate();
	const location = useLocation();

	const { data, isLoading, error } = useQuery<MediaSections, Error>({
		queryKey: ["mediaLayout", params.mediaId],
		queryFn: () => useFujiGet<never, MediaSections>(`/media/${params.mediaId}`)
	});

	const currentSection = React.useMemo(() => {
		const pathSegments = location.pathname.split("/");
		const section = pathSegments[pathSegments.length - 1];
		return section === "pages" || section === "cssSelectors" || section === "gallerySelectors" ? section : "";
	}, [location]);

	const onChangeTab = (value: string) => {
		if (value !== currentSection) {
			navigate(value);
		}
	};

	const ref = React.useRef<EditMediaRefMethods>();
	const onCancel = () => {
		if (ref.current) {
			ref.current.onDiscardChanges();
		}
	};

	const onSave = () => {
		if (ref.current) {
			ref.current.onSaveChanges();
		}
	};

	if (isLoading) {
		return <Loader onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />;
	}

	if (error) {
		navigate("/404");
	}

	return (
		<div className="media">
			<div className="header">
				<span className="page-title">Edit Details</span>
				<span className="media-title">{data?.name}</span>
				<span className="media-title">{}</span>
			</div>
			<div className="body">
				<Tabs
					onPointerEnterCapture={undefined}
					onPointerLeaveCapture={undefined}
					placeholder={"currentTab"}
					value={currentSection}
					onSelected={value => onChangeTab(value as string)}
				>
					<Tab value="" label="Details" />
					<Tab value="pages" label="Pages" disabled />
					<Tab value="cssSelectors" label="CSS Selectors" />
					<Tab value="gallerySelectors" label="Gallery Selectors" disabled />
				</Tabs>
				<Outlet context={[ref, data]} />
			</div>
			<div className="footer">
				<div className="buttons">
					<Button
						onPointerEnterCapture={undefined}
						onPointerLeaveCapture={undefined}
						placeholder={"Cancel"}
						variant="tertiary"
						children="Cancel"
						onClick={onCancel}
					/>

					<Button
						onPointerEnterCapture={undefined}
						onPointerLeaveCapture={undefined}
						placeholder={"Save"}
						variant="primary"
						children="Save Changes"
						onClick={onSave}
					/>
				</div>
			</div>
		</div>
	);
}

export default MediaLayout;
